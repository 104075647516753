import axios from "axios";
import AxiosConfig from "../AxiosConfig";

export function checkAccount(companyId, contactId) {
  return function (dispatch) {
    dispatch({
      type: "CALLS/CHECK_ACCOUNT",
      contactId: contactId,
    });

    const url =
      "calls/check-account/" + contactId + ".json?companyId=" + companyId;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "CALLS/CHECK_ACCOUNT.SUCCESS",
          contactId: contactId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CALLS/CHECK_ACCOUNT.ERROR",
          contactId: contactId,
          payload: error,
        });
      });
  };
}

export function fetchVoicePrice(companyId, countryId) {
  return function (dispatch) {
    dispatch({
      type: "CALLS/VOICE_PRICE",
      countryId: countryId,
    });

    const url =
      "calls/voice-price.json?companyId=" +
      companyId +
      "&countryId=" +
      countryId;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "CALLS/VOICE_PRICE.SUCCESS",
          countryId: countryId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CALLS/VOICE_PRICE.ERROR",
          countryId: countryId,
          payload: error,
        });
      });
  };
}

export function fetchTwilioToken(companyId) {
  return function (dispatch) {
    dispatch({ type: "CALLS/TWILIO_TOKEN" });

    const url = "calls/twilio-token.json?companyId=" + companyId;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "CALLS/TWILIO_TOKEN.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CALLS/TWILIO_TOKEN.ERROR",
          payload: error,
        });
      });
  };
}
